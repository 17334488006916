// defined *MONTHS* constant for calculating *Time Period in GSTR1 and STPI forms*.
const MONTHS = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
let urlContent = window.location.href.split('/');
let firmId = parseInt(urlContent[(urlContent.indexOf("firms") + 1)]);

$(document).ready(function() {
  // Flash messages timeout.
  $(() => $('.fade').fadeOut(3000));

  // Sidebar theme.
  $('#sidebar').mCustomScrollbar({
    theme: "minimal"
  });

  // Closing the Sidebar on Keydown *Escape* Key.
  $(document).on('keydown', function(e) {
    if (e.key == "Escape") {
      Invoicing.sidebarCloseEffect();
    }
  });

  // Closing the Sidebar on clicking the *dismiss button or overlay effect*.
  $('#dismiss, .overlay').on('click', Invoicing.sidebarCloseEffect);

  // Opening the Sidebar on clicking the *sidebar_collapse* button.
  $('#sidebar_collapse').on('click', function() {
    $('#sidebar').addClass('active');
    $('.overlay').addClass('active');
    $('.collapse.in').toggleClass('in');
    $('#brand-name').hide();
  });

  // Adding InvoiceItem's row on clicking *add more items* link.
  $('form').on('click', '.add_fields', function(e) {
    var regexp, time;
    time = new Date().getTime();
    regexp = new RegExp($(this).data('id'), 'g');
    $('.fields').append($(this).data('fields').replace(regexp, time));

    if (Invoicing.taxTypeRefVal() == 'inclusive_tax' || Crediting.taxTypeRefVal() == 'inclusive_tax') {
      $('table tbody tr:last').find('input[name*="[gst_rate]"]').parents('td').addClass('hide');
    } else {
      $('table tbody tr:last').find('input[name*="[gst_rate]"]').parents('td').removeClass('hide');
    }

    if($('#invoice_currency_id').val()) {
      firstCurrencyField = $('table tbody tr:first').find('span.currency:first');
      currencySymbol = firstCurrencyField.text();
      currencyCode = firstCurrencyField.attr('title');
      $('table tbody tr:last').find('span.currency').each(function() {
        $(this).text(currencySymbol).attr('title', currencyCode);
      });
    }
    e.preventDefault();
  });

  // Removing InvoiceItem's row on clicking *delete button icon*.
  $('form').on('click', '.remove_record', function(e) {
    $(this).prev('input[type=hidden]').val('1');
    $(this).closest('tr').addClass('hide');
    Invoicing.totalPriceCalc();
    e.preventDefault();
  });

  // Removing CreditNoteItem's row on clicking *delete button icon*.
  $('form').on('click', '.remove_record', function(e) {
    $(this).prev('input[type=hidden]').val('1');
    $(this).closest('tr').addClass('hide');
    Crediting.totalPriceCalc();
    e.preventDefault();
  });

  // Changing Invoice-form depending on *Invoice tax_type*.
  $('input[name="invoice[tax_type]"]').on('change', function() {
    Invoicing.taxTypeHandler($(this).val());
    Invoicing.totalPriceCalc();
  });

  // Changing CreditNote-form depending on *CreditNote tax_type*.
  $('input[name="credit_note[tax_type]"]').on('change', function() {
    Crediting.taxTypeHandler($(this).val());
    Crediting.totalPriceCalc();
  });

  // GSTR1-form change effect.
  $('.gstr-form').change(function() {
    var periodType;
    $('input[name="period"]').each(function() {
      if ( $(this).is(':checked') ) {
        periodType = $(this).val();
      }
    });

    var month = $('#start_month').val();
    var year = $('#year').val();

    if(periodType && month && year) {
      $('.gstr-form input[value="Generate"]').prop('disabled', false).removeClass('btn-secondary').addClass('btn-primary');
      Invoicing.timePeriod(periodType, month, parseInt(year))
    } else {
      $('.gstr-form input[value="Generate"]').prop('disabled', true).removeClass('btn-primary').addClass('btn-secondary');
    }
  });

  // STPI-form change effect.
  $('.stpi-form').change(function() {
    var startMonth = $('#start_month').val();
    var endMonth = $('#end_month').val();
    var year = $('#year').val();

    if(startMonth && endMonth && year) {
      $('.stpi-form input[value="Generate"]').prop('disabled', false).removeClass('btn-secondary').addClass('btn-primary');
      if(MONTHS.indexOf(startMonth) == MONTHS.indexOf(endMonth)) {
        $('#stpi-period-info').html('** STPI will be generated for Month: <b>' + startMonth + ', ' + year + '</b>.');
      } else {
        var endYear = MONTHS.indexOf(startMonth) > MONTHS.indexOf(endMonth) ? parseInt(year) + 1 : year;
        $('#stpi-period-info').html('** STPI will be generated for Time Period: <b>' + startMonth + ', ' + year + ' - ' + endMonth + ', ' + endYear + '</b>.');
      }
    } else {
      $('.stpi-form input[value="Generate"]').prop('disabled', true).removeClass('btn-primary').addClass('btn-secondary');
    }
  });

  // STPI-MPR-form change effect.
  $('.stpi_mpr-form').change(function() {
    var startMonth = $('#start_month').val();
    var endMonth = $('#end_month').val();
    var year = $('#year').val();

    if(startMonth && endMonth && year) {
      $('.stpi_mpr-form input[value="Generate"]').prop('disabled', false).removeClass('btn-secondary').addClass('btn-primary');
      if(MONTHS.indexOf(startMonth) == MONTHS.indexOf(endMonth)) {
        $('#stpi_mpr-period-info').html('** STPI-MPR will be generated for MONTH: <b>' + startMonth + ', ' + year + '</b>.');
      } else {
        var endYear = MONTHS.indexOf(startMonth) > MONTHS.indexOf(endMonth) ? parseInt(year) + 1 : year;
        $('#stpi_mpr-period-info').html('** STPI-MPR will be generated for Time Period: <b>' + startMonth + ', ' + year + ' - ' + endMonth + ', ' + endYear + '</b>.');
      }
    } else {
      $('.stpi_mpr-form input[value="Generate"]').prop('disabled', true).removeClass('btn-primary').addClass('btn-secondary');
    }
  });

  // TDS-form change effect
  $('.tds-form').change(function() {
    var financialYear = $('#period').val();

    if(financialYear) {
      $('.tds-form input[value="Generate"]').prop('disabled', false).removeClass('btn-secondary').addClass('btn-primary');
      $('#tds-period-info').html(`**TDS Report will be generated for Financial Year: ${financialYear}.`);
    } else {
      $('.tds-form input[value="Generate"]').prop('disabled', true).removeClass('btn-primary').addClass('btn-secondary');
      $('#tds-period-info').html("");
    }
  });

  // On changing *buyer name in Invoice form*, it fetches the contracts info acconding to that.
  $('#invoice_buyer_id').on('change', Invoicing.contractHandler);

  // On changing *buyer name in CreditNote form*, it fetches the contracts info according to that.
  $('#credit_note_buyer_id').on('change', Crediting.contractHandler);

  // On changing *currency name in Invoice-form* it fetches the currency and render.
  $('#invoice_currency_id').on('change', Invoicing.fetchCurrency);

  // On changing *currency name in CreditNote-form* it fetches the currency and render.
  $('#credit_note_currency_id').on('change', Crediting.fetchCurrency);

  // Enable Authenticate button on Two-Factor-Authentication page
  $('#otp').on('keyup mouseout', function() {
    if($(this).val().length == 6){
      $('#authenticate_otp_btn').prop('disabled', false);
    } else {
      $('#authenticate_otp_btn').prop('disabled', true);
    }
  });
});

const Invoicing = {
  // Time Period calculation and showing it's value in GSTR1-form.
  timePeriod: function(periodType, startMonth, startYear) {
    startMonth = MONTHS.indexOf(startMonth);

    if(periodType == 'quarterly') {
      let endMonth = (startMonth + 2) < 12 ? (startMonth + 2) : (startMonth + 2) % 12;
      let endYear = (startMonth > endMonth) ? startYear + 1 : startYear;

      $('#period-info').html('** GSTR1 will be generated for Time Period: <b>' + MONTHS[startMonth] + ', ' + startYear + ' - ' + MONTHS[endMonth] + ', ' + endYear + '</b>.');
    } else {
      $('#period-info').html('** GSTR1 will be generated for Month: <b>' + MONTHS[startMonth] + ', ' + startYear + '</b>.');
    }
  },

  // Sidebar closing effects.
  sidebarCloseEffect: function() {
    $('#sidebar').removeClass('active');
    $('.overlay').removeClass('active');
    $('#brand-name').show();
  },

  // Getting Invoice tax_type value on checked *tax_type radio button*.
  taxTypeRefVal: function() {
    var checkedTypeVal;
    $('input[name="invoice[tax_type]"]').each(function() {
      if ( $(this).is(':checked') ) {
        checkedTypeVal = $(this).val();
      }
    });
    return checkedTypeVal;
  },

  // Invoice tax_type change effects in Invoice-form.
  taxTypeHandler: function(taxType) {
    if (taxType === 'inclusive_tax') {
      $('table tr:first').find('th.gst').addClass('hide');
      $('table tr input[name*="[gst_rate]"]').each(function() {
        $(this).parents('td').addClass('hide');
      });

      $('table tfoot.form tr').not(':last').each(function() {
        $(this).addClass('hide');
      });

      $('table tfoot.form tr:last').addClass('top-border').find('td:first').attr('colspan', '4').find('a').removeClass('hide');
    } else {
      $('table tfoot.form tr').find('td[colspan="4"]').each(function() {
        $(this).attr('colspan', '5');
      });

      $('table tr:first').find('th.gst').removeClass('hide');
      $('table tr input[name*="[gst_rate]"]').each(function() {
        $(this).parents('td').removeClass('hide');
      });

      $('table tfoot.form tr:last').removeClass('top-border').find('td:first a').addClass('hide');

      $('table tfoot.form tr').each(function() {
        $(this).removeClass('hide');
      });

      if (taxType === 'with_gst') {
        $('input[name="total_gst"]').parents('tr').addClass('hide');
        $('table.layout-fixed thead th.gst').text("GST Rate");
      } else {
        $('input[name="total_cgst"]').parents('tr').addClass('hide').next().addClass('hide');
        $('table.layout-fixed thead th.gst').text("IGST Rate");
      }
    }
  },

  // Fetching all contracts details depending upon *buyer* using ajax.
  contractHandler: function() {
    let buyerId = $('#invoice_buyer_id').val();

    if (buyerId != '') {
      let url = '/firms/' + firmId + '/buyers/' + buyerId + '/contracts_info.json';
      $.ajax(url, {
        method: 'GET',
        dataType: 'json',
        success: function(data, status, xhr) {
          options = '<option value>Select Contract Number : Signing Date</option>';

          data['contracts'].forEach(function(contract) {
            options += '<option value="' + contract.id + '">' + contract.contract_detail + '</option>';
          })

          $('#invoice_contract_id').html(options);
        }
      });
    }
  },

  // Fetching Currency using ajax.
  fetchCurrency: function() {
    var currencyId = $('#invoice_currency_id').val();

    if (currencyId != '') {
      let url = '/currencies/' + currencyId + '/currency_info.json';
      $.ajax(url, {
        method: 'GET',
        dataType: 'json',
        success: function(data, status, xhr) {
          $('span.currency').each(function() {
            $(this).text(data.currency.symbol).attr('title', data.currency.name);
          });
        }
      });
    }
  },

  // fetching Product using ajax.
  fetchProductInfo: function(link) {
    var productField = $(link);
    var productId = productField.val();

    if (productId != '') {
      let url = '/firms/' + firmId + '/products/' + productId + '/product_info.json';
      $.ajax(url, {
        method: 'GET',
        dataType: 'json',
        success: function(data, status, xhr) {
          productField.parents('tr').find('input[name*="[hsn]"]').val(data.product.hsn);
          productField.parents('tr').find('input[name*="[gst_rate]"]').val(data.product.gst_rate === null ? 0 : data.product.gst_rate);
          productField.parents('tr').find('div span.uom').text(data.unit);
          Invoicing.renderAndCalculate(link);
        }
      });
    }
  },

  // Calculating the amount on changing InvoiceItem row.
  renderAndCalculate: function(link) {
    var productField = $(link).parents('tr').find('select[name*="[product_id]"]');
    if (productField.val() != '') {
      var quantityField = productField.parents('tr').find('input[name*="[quantity]"]');
      var quantity = parseFloat(quantityField.val() == '' ? '0' : quantityField.val());

      var rateField = productField.parents('tr').find('input[name*="[rate]"]');
      var rate = parseFloat(rateField.val() == '' ? '0' : rateField.val());

      var discountField = productField.parents('tr').find('input[name*="[discount]"]');
      var discountRate = parseFloat(discountField.val() == '' ? '0' : discountField.val());

      var amount = Math.round((100 - discountRate) * quantity * rate) / 100;
      productField.parents('tr').find('input[name="amount"]').val(amount.toFixed(2));

      Invoicing.totalPriceCalc();
    }
  },

  // calculating SubTotal, CGST, SGST, IGST and Total amount.
  totalPriceCalc: function() {
    var totalAmount = 0;
    var totalCgstSgst = 0;
    var taxTypeVal = Invoicing.taxTypeRefVal();

    $('table select').each(function() {
      var gst = $(this).parents('tr').find('input[name*="[gst_rate]"]').val();
      var amount = $(this).parents('tr').find('input[name="amount"]').val();

      if ($(this).parents('tr').find('select[name*="[product_id]"]').val() != '') {
        var removedElementVal = $(this).parents('tr').find('input[name*="[_destroy]"]').val();
        if (removedElementVal === 'false') {
          totalAmount += parseFloat(amount);
          if ((taxTypeVal !== 'inclusive_tax') && (gst !== '')) {
            totalCgstSgst += Math.round(parseFloat(gst) * parseFloat(amount) / 2) / 100;
          }
        }
      }
    });


    $('input[name="sub_total_amount"]').val((totalAmount).toFixed(2));
    $('input[name="total_gst"]').val((totalCgstSgst * 2).toFixed(2));
    $('input[name="total_cgst"]').val(totalCgstSgst.toFixed(2));
    $('input[name="total_sgst"]').val(totalCgstSgst.toFixed(2));
    $('input[name="invoice[total_amount]"]').val((totalAmount + totalCgstSgst * 2).toFixed(2));
  }
}

const Crediting = {
  // Getting CreditNote tax_type value on checked *tax_type radio button*.
  taxTypeRefVal: function() {
    var checkedTypeVal;
    $('input[name="credit_note[tax_type]"]').each(function() {
      if ( $(this).is(':checked') ) {
        checkedTypeVal = $(this).val();
      }
    });
    return checkedTypeVal;
  },

  // CreditNote tax_type change effects in CreditNote-form.
  taxTypeHandler: function(taxType) {
    if (taxType === 'inclusive_tax') {
      $('table tr:first').find('th.gst').addClass('hide');
      $('table tr input[name*="[gst_rate]"]').each(function() {
        $(this).parents('td').addClass('hide');
      });

      $('table tfoot.form tr').not(':last').each(function() {
        $(this).addClass('hide');
      });

      $('table tfoot.form tr:last').addClass('top-border').find('td:first').attr('colspan', '4').find('a').removeClass('hide');
    } else {
      $('table tfoot.form tr').find('td[colspan="4"]').each(function() {
        $(this).attr('colspan', '5');
      });

      $('table tr:first').find('th.gst').removeClass('hide');
      $('table tr input[name*="[gst_rate]"]').each(function() {
        $(this).parents('td').removeClass('hide');
      });

      $('table tfoot.form tr:last').removeClass('top-border').find('td:first a').addClass('hide');

      $('table tfoot.form tr').each(function() {
        $(this).removeClass('hide');
      });

      if (taxType === 'with_gst') {
        $('input[name="total_gst"]').parents('tr').addClass('hide');
        $('table.layout-fixed thead th.gst').text("GST Rate");
      } else {
        $('input[name="total_cgst"]').parents('tr').addClass('hide').next().addClass('hide');
        $('table.layout-fixed thead th.gst').text("IGST Rate");
      }
    }
  },

  // Fetching all contracts details depending upon *buyer* using ajax.
  contractHandler: function() {
    let buyerId = $('#credit_note_buyer_id').val();

    if (buyerId != '') {
      let url = '/firms/' + firmId + '/buyers/' + buyerId + '/contracts_info.json';
      $.ajax(url, {
        method: 'GET',
        dataType: 'json',
        success: function(data, status, xhr) {
          options = '<option value>Select Contract Number : Signing Date</option>';

          data['contracts'].forEach(function(contract) {
            options += '<option value="' + contract.id + '">' + contract.contract_detail + '</option>';
          })

          $('#credit_note_contract_id').html(options);
        }
      });
    }
  },

  // Fetching Currency using ajax.
  fetchCurrency: function() {
    var currencyId = $('#credit_note_currency_id').val();

    if (currencyId != '') {
      let url = '/currencies/' + currencyId + '/currency_info.json';
      $.ajax(url, {
        method: 'GET',
        dataType: 'json',
        success: function(data, status, xhr) {
          $('span.currency').each(function() {
            $(this).text(data.currency.symbol).attr('title', data.currency.name);
          });
        }
      });
    }
  },

  // fetching Product using ajax.
  fetchProductInfo: function(link) {
    var productField = $(link);
    var productId = productField.val();

    if (productId != '') {
      let url = '/firms/' + firmId + '/products/' + productId + '/product_info.json';
      $.ajax(url, {
        method: 'GET',
        dataType: 'json',
        success: function(data, status, xhr) {
          productField.parents('tr').find('input[name*="[hsn]"]').val(data.product.hsn);
          productField.parents('tr').find('input[name*="[gst_rate]"]').val(data.product.gst_rate === null ? 0 : data.product.gst_rate);
          productField.parents('tr').find('div span.uom').text(data.unit);
          Crediting.renderAndCalculate(link);
        }
      });
    }
  },

    // Calculating the amount on changing CreditNoteItem row.
    renderAndCalculate: function(link) {
      var productField = $(link).parents('tr').find('select[name*="[product_id]"]');
      if (productField.val() != '') {
        var quantityField = productField.parents('tr').find('input[name*="[quantity]"]');
        var quantity = parseFloat(quantityField.val() == '' ? '0' : quantityField.val());

        var rateField = productField.parents('tr').find('input[name*="[rate]"]');
        var rate = parseFloat(rateField.val() == '' ? '0' : rateField.val());

        var discountField = productField.parents('tr').find('input[name*="[discount]"]');
        var discountRate = parseFloat(discountField.val() == '' ? '0' : discountField.val());

        var amount = Math.round((100 - discountRate) * quantity * rate) / 100;
        productField.parents('tr').find('input[name="amount"]').val(amount.toFixed(2));

        Crediting.totalPriceCalc();
      }
    },

  // calculating SubTotal, CGST, SGST, IGST and Total amount.
  totalPriceCalc: function() {
    var totalAmount = 0;
    var totalCgstSgst = 0;
    var taxTypeVal = Crediting.taxTypeRefVal();

    $('table select').each(function() {
      var gst = $(this).parents('tr').find('input[name*="[gst_rate]"]').val();
      var amount = $(this).parents('tr').find('input[name="amount"]').val();

      if ($(this).parents('tr').find('select[name*="[product_id]"]').val() != '') {
        var removedElementVal = $(this).parents('tr').find('input[name*="[_destroy]"]').val();
        if (removedElementVal === 'false') {
          totalAmount += parseFloat(amount);
          if ((taxTypeVal !== 'inclusive_tax') && (gst !== '')) {
            totalCgstSgst += Math.round(parseFloat(gst) * parseFloat(amount) / 2) / 100;
          }
        }
      }
    });

    $('input[name="sub_total_amount"]').val((totalAmount).toFixed(2));
    $('input[name="total_gst"]').val((totalCgstSgst * 2).toFixed(2));
    $('input[name="total_cgst"]').val(totalCgstSgst.toFixed(2));
    $('input[name="total_sgst"]').val(totalCgstSgst.toFixed(2));
    $('input[name="credit_note[total_amount]"]').val((totalAmount + totalCgstSgst * 2).toFixed(2));
  }
}

// *discountHandler* is called on changing *discount rate in InvoiceItem's row*.
window.discountHandler = function(link) {
  if (Invoicing.taxTypeRefVal()) {
    Invoicing.renderAndCalculate(link)
  } else {
    Crediting.renderAndCalculate(link);
  }
}

// *productHandler* is called on changing *product's name field in InvoiceItem's row*.
window.productHandler = function(link) {
  if (Invoicing.taxTypeRefVal()) {
    Invoicing.fetchProductInfo(link)
  } else {
    Crediting.fetchProductInfo(link);
  }
}

// *quantityHandler* is called on changing *quantity field in InvoiceItem's row*.
window.quantityHandler = function(link) {
  if (Invoicing.taxTypeRefVal()) {
    Invoicing.renderAndCalculate(link);
  } else {
    Crediting.renderAndCalculate(link);
  }
}

// *rateHandler* is called on changing *InvoiceItem's rate in InvoiceItem's row*.
window.rateHandler = function(link) {
  if (Invoicing.taxTypeRefVal()) {
    Invoicing.renderAndCalculate(link);
  } else {
    Crediting.renderAndCalculate(link);
  }
}

// Auto submit on changing *Firm, Buyer, Email-status, Payment-status and Financial-year filter fields in Invoice index page*.
$(document).on('change','.buyer-dropdown, .bank-dropdown, .email-status-dropdown, .payment-status-dropdown, .proforma-check-dropdown, .financial-year', function() {
  $(this).parent().submit();
});
